









































import {Component, Vue} from 'vue-property-decorator';
import CourseTime from '@/components/CourseTime.vue'
import {getCourseByCoachIdAsync} from '@/api/course'
import {CourseDto} from '@/dto/CourseDto';

@Component({
  components: {
    CourseTime
  }
})
export default class CourseOpening extends Vue {
  private courses: any = {
    items: new Array<CourseDto>()
  }
  private loading = false;

  private async activated() {
    const qdate = (this.$refs.courseTime as any).queryDate
    await this.getList(qdate)
  }

  private async getList(queryDate: string) {
    const dateNow = new Date()
    this.loading = true
    const year = dateNow.getFullYear();
    const dt = `${year}-${queryDate.replace('.', '-')}`
    const result = await getCourseByCoachIdAsync(dt, 0)
    this.courses = result;
    this.loading = false
  }
}
